function placeholderInput() {
    $(".form-input")
    .on("focus", function(){
        $(this).next().css("top","-25px");
        $(this).next().css("left","0");
        $(this).addClass('active-input');
    })
    .on("blur", function(){
        if ($(this).val() == "") {
            $(this).next().css("left","15px");
            $(this).next().css("top","15px");
            $(this).removeClass('active-input');
        }
        var inputWraper = $(this).closest('.input-wrapper'),
        spanError = inputWraper.find('.span__error');

        inputWraper.removeClass('forms__error');
        spanError.css('display', 'none');
    });

    setTimeout(function() {
        $(".form-input").each(function(){
            if($(this).val() != '') {
                $(this).next().css("top","-25px");
                $(this).next().css("left","0");
                $(this).addClass('active-input');
            }
        });
    }, 100);
}

$(document).ready(function() {
    var lang = $('html').attr('lang');
    var qty;
    var ajax_url;

    function reloadTopCart() {
        $.ajax({
            type: "POST",
            url: routes.postReloadTopCart,
            data: {},
            dataType: "json",
            success: function(data) {
                $("#top_cart").empty().html(data.top)
            }
        })
    };

    function showEditOrder () {
        $.ajax ({
            type: "POST",
            url: routes.postReloadOrderCart,
            dataType: "json",
            beforeSend: function(){
                $('#spin').show();
            },
    		success: function(data) {
                $('#spin').hide();
                $("#order_cart").empty().html(data.body);
    		}
    	});
    }

    $(document).on( 'click', '.js-to-cart', function(e){
        var btn = $(this);
        var langInCart = btn.attr('data-in-cart');
        var good_id = $(this).attr('data-id');
        var good_color = $(this).attr('data-color');
        var good_size = $(this).attr('data-size');
        var count = $(this).attr('data-count');
        var el = $(this);
        if(el.hasClass('in_cart')){
            ajax_url = routes.postShowGoodOrder;
        } else if (el.hasClass('add-to-cart')) {
            ajax_url = routes.postReloadGoodOrder;
        } else {
            ajax_url = routes.postReloadGoodOrder;
        }
        $(".good-modal-wrapper").empty();
        $.ajax ({
            type: "POST",
            url: ajax_url,
            beforeSend: function(){
                $('#spin').show();
            },
            data: {
                "good_id": good_id,
                "color": good_color,
                "size": good_size,
                "count": count,
                "lang": lang,
            },
            success: function(data) {
                $('#spin').hide();
                reloadTopCart();
                btn.addClass('in_cart').removeClass('add-to-cart');
                if (!el.hasClass('dot-good-btn-blue')) {
                    btn.text(langInCart);
                }
                $(".good-modal-wrapper").html(data);
                showEditOrder ();
            },
        });
    });


  // modals
  $(document).on( 'click', '.open-modal-js', function(e){
      e.preventDefault();
      e.stopPropagation();
      if ($(this).is('a')) {
          var popup = $(this).attr('href');
      } else {
          var popup = $(this).attr('data-popup');
      }

      $(document).one('keydown', function(e) {
          if (e.keyCode == 27) {
              $('.popup').removeClass('open');
              $('.side-popup').removeClass('open');
              $('body').removeClass('overlay');
              $('html').removeClass('not-overflow');
          }
      });

    if($(e.target).is('.quick-buy-btn') || $(e.target).is('.get_price') || $(e.target).is('.get_good')) {
        var id = $(this).attr('data-id');
        var color_id = $.trim($(this).attr('data-color'));
        var size_id = $.trim($(this).attr('data-size'));
        $('.' + popup).find('.value_id').val(id);
        $('.' + popup).find('.value_color').val(color_id);
        $('.' + popup).find('.value_size').val(size_id);
        // var count = $(this).attr('data-count');
        // $('.' + popup).find('.count').val(count);
    };

    if($(e.target).is('.registration-tour-btn')) {
        var name = $('.addition-visual-title').text();
        $('.' + popup).find('.tour-name').val(name);
    };


      if($(e.target).is('.get_city_js')) {
        //   выбор нужного селекта
          var city = $(this).attr('data-city');
          var city_crm = $('.city-js option[value="'+ city +'"]').attr('data-crm');
          $('.city-js option').removeAttr('selected');
          $('.city-js option[value="'+ city +'"]').attr('selected','selected');
          $('.city_crm').val(city_crm);
          $( ".city-js" ).change(function() {
              var city_value = $(".city-js option:selected").attr('data-crm');
              $('.city_crm').val(city_value);
          });

          $('.city-js').selectric('refresh');
        //  закрытие модалки с контактами в хедере
          $('.header-contact-name-wrap').removeClass('active');
          $('.header-contact-info').removeClass('active');
      }


      if($('.popup').is('.open')) {
          $('.popup').removeClass('open');
          $('body').removeClass('overlay');
          $('html').removeClass('not-overflow');
      };

      if($('.side-popup').is('.open')) {
          $('.side-popup').removeClass('open');
          $('body').removeClass('overlay');
          $('html').removeClass('not-overflow');
      };

      $('.' + popup).addClass('open');
      placeholderInput();
      $('.login-block').addClass('js_open');
      $('.popup-link--login').addClass('js_open');

      if ($('.' + popup).find("input").length) {
          $('.' + popup).find("input")[0].focus();
      }
      $('body').addClass('overlay');
      $('html').addClass('not-overflow');

      $('.popup-close').on('click', function (e) {
          e.stopPropagation();e.preventDefault();
          $('.' + popup).removeClass('open');
          $('body').removeClass('overlay');
          $('html').removeClass('not-overflow');
      });
  });

  $(document).on('click', '.popup-close', function(e){
      e.stopPropagation();e.preventDefault();
      $('body').removeClass('overlay');
      $('html').removeClass('not-overflow');
  });

  $(document).on('click', '.js_open_block', function(e){
    e.preventDefault();
    e.stopPropagation();
    var title = $(this).attr('href');
    var blocks = $(this).closest('.popup-content').find('.popup-form');
    var block = $(`.${title}-block`);

    $('.js_open_block').removeClass('js_open');
    $(this).addClass('js_open');

    $(blocks).each(function(){
      $(this).removeClass('js_open');
      $(block).addClass('js_open');
    });
  });

  // close all popup click overlay
  $(document).click(function(e) {
      var el = $(e.target);
      if (el.is(".popup.open") || el.is(".side-popup.open")) {
          el.removeClass('open');
          $('body').removeClass('overlay');
          $('html').removeClass('not-overflow');
      } else if (el.is(".header-nav.open")) {
          $('.menu__icon').removeClass('open');
          $('.header-nav').removeClass('open');
          if (window.innerWidth > 1024) {
              $('.right-block').removeClass('open-menu');
          }
          $('.main-cat-link').removeClass('active');
          $('body').removeClass('overlay');
          $('html').removeClass('not-overflow');
          if (window.innerWidth < 767) {
              $('.subcategories-nav').css({'z-index' : '-1'});
          }
      }
  });
});
