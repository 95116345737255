// jQuery
require('../../../node_modules/jquery/dist/jquery.min');
window.$ = window.jQuery = require('jquery');
// nouislider
import noUiSlider from "nouislider/distribute/nouislider.min";
window.noUiSlider = require('nouislider');
// wNumb
import wNumb from "wnumb/wNumb.min";
window.wNumb = require('wnumb');
// form Stickyfill
import Stickyfill from "stickyfilljs/dist/stickyfill.min";
window.Stickyfill = require('stickyfilljs');
// import validate from "jquery-validation";
require("jquery-validation");
// slick
import slick from "slick-carousel/slick/slick.min.js";
// input mask
import Inputmask from "inputmask";
// magnific_popup
import magnificPopup from "magnific-popup/dist/jquery.magnific-popup.min.js";
// selectric
import selectric from "selectric";
// malihu_scrollbar
import mCustomScrollbar from "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js";
// tooltipster
import tooltipster from "tooltipster";
//cabinet
require("bootstrap");
require('x-editable/dist/bootstrap3-editable/js/bootstrap-editable');
require('bootstrap-switch');
require('pgenerator');
// eventstouch
var loadTouchEvents = require('jquery-touch-events');
loadTouchEvents($);
// lightcase
require("lightcase");
// lazysizes
import 'lazysizes';
// slick
require('./slick');
// modals
require('./modals');
// forms
require('./forms');
// main
require('./main');
