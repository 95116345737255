$(window).on('load resize', function () {
    if($('.main-product-slick').length) {
        $('.main-product-slick').each(function() {
            if (window.innerWidth < 1401) {
                if (!$(this).hasClass('slick-slider')) {
                    $(this).slick({
                        arrows: true,
                        prevArrow: '<div class="good-arrow-prev"></div>',
                        nextArrow: '<div class="good-arrow-next"></div>',
                        infinite: true,
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 1025,
                                settings: {
                                    slidesToShow: 4,
                                }
                            },
                            {
                                breakpoint: 891,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 2,
                                }
                            },
                            {
                                breakpoint: 577,
                                settings: {
                                    slidesToShow: 1,
                                }
                            }
                        ]
                    });
                }
            } else {
                if ($(this).hasClass('slick-slider')) {
                    $(this).slick('destroy');
                }
            }
        })
    }

    if($('.blog-slider').length) {
        $('.blog-slider').each(function() {
            if (window.innerWidth < 1025) {
                if (!$(this).hasClass('slick-slider')) {
                    $(this).slick({
                        arrows: false,
                        dots: true,
                        infinite: true,
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 1,
                                }
                            }
                        ]
                    });
                }
            } else {
                if ($(this).hasClass('slick-slider')) {
                    $(this).slick('destroy');
                }
            }
        })
    }

});

$(document).ready(function() {
    // additions-slider
    if($('.slider-wrap').length) {
        $(".slider-wrap").slick({
            lazyLoad: 'ondemand',
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: '.additions-prev-arrow-slider',
            nextArrow: '.additions-next-arrow-slider',
            infinite: false,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 577,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        })
    }


    if($('.ather-product-slick').length) {
        $('.ather-product-slick').each(function() {
            $(this).slick({
                arrows: true,
                prevArrow: '<div class="good-arrow-prev"></div>',
                nextArrow: '<div class="good-arrow-next"></div>',
                infinite: true,
                slidesToShow: 6,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1401,
                        settings: {
                            slidesToShow: 5,
                        }
                    },
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 891,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 577,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ]
            });
        })
    }
});
