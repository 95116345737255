$(window).on('load', function () {
   $('#preloader').delay(350).fadeOut('slow');
});

function placeholderInput() {
    $(".form-input")
    .on("focus", function(){
        $(this).next().css("top","-25px");
        $(this).next().css("left","0");
        $(this).addClass('active-input');
    })
    .on("blur", function(){
        if ($(this).val() == "") {
            $(this).next().css("left","15px");
            $(this).next().css("top","15px");
            $(this).removeClass('active-input');
        }
        var inputWraper = $(this).closest('.input-wrapper'),
        spanError = inputWraper.find('.span__error');

        inputWraper.removeClass('forms__error');
        spanError.css('display', 'none');
    });

    setTimeout(function() {
        $(".form-input").each(function(){
            if($(this).val() != '') {
                $(this).next().css("top","-25px");
                $(this).next().css("left","0");
                $(this).addClass('active-input');
            }
        });
    }, 100);
}

function initSizeTabs() {
    $('.tabs-size').each(function() {
        var active_tab = $(this).find('.tab-link-size').first();
        var tabs_container = $(this).closest('.tabs-container');
        var selectTab = active_tab.attr('data-tab');
        active_tab.addClass('active');
        tabs_container.find('.tab-content-size').hide();
        $('#'+selectTab).fadeIn();
    });
    $('.tabs-size li').click(function(e) {
        var parent = $(this).closest('.tabs-container');
        var selectTab = $(this).attr('data-tab');
        parent.find('.tabs-size li').removeClass('active');
        $(this).addClass('active');
        parent.find('.tab-content-size').hide();
        $('#'+selectTab).fadeIn();
    });
}
// rename button to-cart
function goodBuy() {
    $('.js-to-cart').each(function() {
      if ($(this).hasClass("add-product-cart")) {
        var addcart = $(this).attr('data-to-cart');
        $(this).text(addcart);
      } else if ($(this).hasClass("in_cart")) {
          var incart = $(this).attr('data-in-cart');
          $(this).text(incart);
        }
    });
}

function initScrollbar() {
    $(".scroll-js").mCustomScrollbar({
        axis:"y",
        documentTouchScroll: true,
    });
}
function initLightcase() {
    $('a[data-rel="lightcase:gallery"]').lightcase({
        transition: 'scrollHorizontal',
        showSequenceInfo: true,
        showTitle: true,
        showCaption: true,
        shrinkFactor: 0.9,
        swipe: true,
        navigateEndless: false,
        maxWidth: '90%',
        maxHeight: '90%'
    });
}

function closeMobileFilter() {
    $('.catalogue-list-filters').toggleClass("active");
    $('.filters-overlay').toggleClass("active");
    $('body').toggleClass("overlay");
    $('html').toggleClass('not-overflow');
}

function tabsDetailsProduct(a) {
    if($('.product-details-accordeon').length) {
        var first_active_link = $('.product-details-accordeon').find('.product-descriptions__name').first();
        first_active_link.addClass('active');
        first_active_link.next('.product-descriptions__accordion').show();
        if (a != false) {
            $(".product-descriptions__name").on("click", function() {
                var accordion_link = $(this);
                $('.product-descriptions__name').removeClass('active');
                $('.product-descriptions__accordion').hide();
                accordion_link.addClass("active")
                accordion_link.next(".product-descriptions__accordion").show();
            });
        }
    }
}

function accordeonDetailsProduct(a) {
    if ($('.product-descriptions__name').length) {
        $('.product-descriptions__name').unbind();
        if (a != false) {
            $(".product-descriptions__name").on("click", function() {
                var accordion_link = $(this);
                if (accordion_link.hasClass("active")) {
                    accordion_link.removeClass("active");
                    accordion_link.next(".product-descriptions__accordion").slideUp(200);
                } else {
                    $(".product-descriptions__name").removeClass("active");
                    accordion_link.addClass("active");
                    $(".product-descriptions__accordion").slideUp(200);
                    accordion_link.next(".product-descriptions__accordion").slideDown(200);
                }
            });
        } else {
            if (!$('.product-descriptions__name').hasClass('active')) {
                var first_active_link = $('.product-details-accordeon').find('.product-descriptions__name').first();
                first_active_link.addClass('active');
                first_active_link.next('.product-descriptions__accordion').fadeIn();
            }
            $(".product-descriptions__name").on("click", function() {
                var accordion_link = $(this);
                $('.product-descriptions__name').removeClass('active');
                $('.product-descriptions__accordion').hide();
                accordion_link.addClass("active");
                accordion_link.next(".product-descriptions__accordion").fadeIn();
            });
        }
    }
}

function scrollDetailsProduct() {
    if ($('.details-link').length) {
        $('.details-link').on('click', function(e) {
            var heightHeader = $("header").outerHeight();
            var el = $(this).attr('data-link');
            $('html, body').animate({ scrollTop: ($('.product-details-wrap').offset().top - heightHeader) }, 500);
            $(".product-descriptions__name[data-link^='" + el + "']").trigger( "click" );
        })
    }
}

$(document).on( 'click', '.footer-name-js', function(e){
  if ($(this).hasClass('footer-item-name footer-name-js')) {
      var submenu = $(this).closest('.footer-contacts-item').find('.footer-item-nav');
      var icon =  $(this).closest('.footer-contacts-item').find('.footer-item-icon');
      $(this).toggleClass('active');
      if($(this).hasClass('active')) {
          icon.removeClass('plus').addClass('active');
          submenu.slideDown(200);
      } else {
          icon.removeClass('minus').removeClass('active');
          submenu.slideUp(200);
      }
   } else {
       var submenu = $(this).closest('.footer-nav-wrap').find('.footer-item-nav');
       var icon =  $(this).closest('.footer-nav-wrap').find('.footer-item-icon');
       $(this).toggleClass('active');
       if($(this).hasClass('active')) {
           icon.removeClass('plus').addClass('active');
           submenu.slideDown(200);
       } else {
           icon.removeClass('minus').removeClass('active');
           submenu.slideUp(200);
       }
   };
});


$(document).ready(function() {
    // $('form').not('.search-form').keydown(function(event){
    //    if(event.keyCode == 13) {
    //      event.preventDefault();
    //      return false;
    //    }
    // });

    if ($('.popup-js').length) {
        $(function () {
        	$('.popup-js').magnificPopup({
        		fixedContentPos: true,
        	});
        });
    }

    // for solution page
    if ($('.interactive-page').length) {
        $(document).on('click', '.dot-click-js', function(e) {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $('.dot-click-js').removeClass('active');
                $(this).addClass('active');
            }
        });
    }


    // accordion accessories main page
    if($('.header-accessories-list').length) {
        $(".description-inner-subtitle").on("click", function() {
    		var accordion_link = $(this);
    		var accordion_icon = $(this).find('.description-inner-subtitle-icon');
    		if (accordion_link.hasClass("active")) {
    			accordion_link.removeClass("active");
    			accordion_link.siblings(".accessories-list").slideUp(200);
    			accordion_icon.removeClass("active");
    		} else {
    			$(".description-inner-subtitle-icon").removeClass("active");
    			accordion_icon.addClass("active");
    			$(".description-inner-subtitle").removeClass("active");
    			accordion_link.addClass("active");
    			$(".accessories-list").slideUp(200);
    			accordion_link.siblings(".accessories-list").slideDown(200);
    		}
    	});
    }
    // accordion accessories header
    $(".subcategories-group-nav-title").on("click", function() {
		var accordion_link = $(this);
		var accordion_icon = $(this).find('.subcategories-group-nav-icon');
		if (accordion_link.hasClass("active")) {
			accordion_link.removeClass("active");
			accordion_link.siblings(".subcategories-group-nav-list").slideUp(200);
			accordion_icon.removeClass("active");
		} else {
			$(".subcategories-group-nav-icon").removeClass("active");
			accordion_icon.addClass("active");
			$(".subcategories-group-nav-title").removeClass("active");
			accordion_link.addClass("active");
			$(".subcategories-group-nav-list").slideUp(200);
			accordion_link.siblings(".subcategories-group-nav-list").slideDown(200);
		}
	});

    placeholderInput();
    // add unallocated characteristics
    if($('.other-chars').length && $('.append-other-chars').length) {
        $(".other-chars").appendTo( $(".append-other-chars") );
        $(".other-chars").css('display', 'block');
    }

    if($('.good_page').length) {
        if($('.check-availability-js').length && !$('.choice-details').length) {
            $('.availability-js').html($('.buy-btn').text());
        }
    }

    // init tabs size
    if($('.tabs-size').length) {
        initSizeTabs();
    }

    // rename button to-cart
    if($('.js-to-cart').length) {
        goodBuy();
    }

    if($('.scroll-top-js').length) {
        $(document).on('click', '.scroll-top-js', function(e) {
          $('html, body').animate({
            scrollTop: 0
          }, 450)
        })
    }

    $('.js_load_size').click(function (e) {
        $('#spin').show();
        e.stopPropagation();e.preventDefault();
        var good_id = $(this).attr('data-good-id');
        var val_id = $(this).attr('data-val-id');
        var char_id = $(this).attr('data-char-id');
        var lang = $('html').attr('lang');
        $.post(routes.postLoadNextVariant, { 'good_id' : good_id, 'val_id' : val_id, 'char_id' : char_id, 'lang' : lang }, function(data) {
            if (window.innerWidth < 1025) {
                if ($('.product-img-block').hasClass('slick-slider')) {
                    $(".product-img-block").slick('destroy');
                }
            }
            $(".choice-details-append").empty();
            $(".choice-details-append").append(data.result.sizes);
            $('.product-img-block').empty();
            $('.product-img-block').append(data.result.images);
            $('.product-details-img').empty();
            $('.product-details-img').append(data.result.first_image);
            if (window.innerWidth < 1025) {
                if (!$('.product-img-block').hasClass('slick-slider')) {
                    $(".product-img-block").slick({
                        dots: true,
                        arrows: false,
                        infinite: false,
                    });
                }
            }
            $('.buy-btn, .quick-buy-btn').addClass("btn-disabled-js");
            $('.buy-btn, .quick-buy-btn').on('click', function(e) {
                e.stopPropagation();e.preventDefault();
            })
            if($('.sort_item.active.js_load_size').length) {
                var color = $('.sort_item.active.js_load_size').text();
                var goodId = $('.good_page').attr('data-good-id');
                var sizeGoodId = $(".size-variants-js .sort_item[data-good-id^='" + goodId + "']").attr('data-good-id');
                var size = $(".size-variants-js .sort_item[data-good-id^='" + goodId + "']").text();
                var sizeBtn = $(".size-variants-js .sort_item[data-good-id^='" + goodId + "']").find('.size-variant-js').text();
                $('.color-text-js').html(color);
                $('.color-text-js').closest('.sort_item-btn').addClass('selected');
                if(goodId == sizeGoodId) {
                    $('.size-text-js').html(size);
                    $('.size-text-js').closest('.sort_item-btn').addClass('selected');
                    $('.sort_item-btn').removeClass('red_border');
                    if($('.sort_item-btn.selected').length) {
                        $('.buy-btn, .quick-buy-btn').removeClass("btn-disabled-js");
                        $('.buy-btn, .quick-buy-btn').unbind('click');
                        $('.buy-btn').attr('data-size', sizeBtn);
                        $('.buy-btn').attr('data-color', color);
                        if($('.quick-buy-btn').length) {
                            $('.quick-buy-btn').attr('data-size', sizeBtn);
                            $('.quick-buy-btn').attr('data-color', color);
                        }
                    }
                }
                if($('.check-availability-js').length) {
                    $('.availability-js').html($('.buy-btn').text());
                }
            }
            initLightcase();
            $('#spin').hide();
        });
    });


    if($('.sort_item.active.js_load_size').length) {
        $('.sort_item.active.js_load_size').trigger( "click" );
    }

    if($('.sort_item.active').length) {
        $('.color-text-js').html($('.sort_item.active').find('.size-variant-js').text());
        $('.article-js').html($('.buy-btn').attr('data-article'));
        $('button').removeClass("btn-disabled-js");
        if($('.check-availability-js').length) {
            $('.availability-js').html($('.buy-btn').text());
        }
    }


    // if($('.sort_item.active.js_load_size').length) {
    //     var color = $('.sort_item.active.js_load_size').text();
    //     $('.sort_item.active.js_load_size').trigger( "click" );
    //     $('.color-text-js').html(color);
    //     $('.color-text-js').closest('.sort_item-btn').addClass('selected');
    //     if($('.good_page').length) {
    //         var goodId = $('.good_page').attr('data-good-id');
    //         setTimeout(function(){
    //             var size = $(".size-variants-js .sort_item[data-good-id^='" + goodId + "']").text();
    //             var sizeBtn = $(".size-variants-js .sort_item[data-good-id^='" + goodId + "']").find('.size-variant-js').text();
    //             $('.size-text-js').html(size);
    //             $('.size-text-js').closest('.sort_item-btn').addClass('selected');
    //             $('.sort_item-btn').removeClass('red_border');
    //             if($('button').hasClass('btn-disabled-js')) {
    //                 $('button').removeClass("btn-disabled-js");
    //                 $('button').unbind('click');
    //                 $('.buy-btn').attr('data-size', sizeBtn);
    //                 $('.buy-btn').attr('data-color', color);
    //                 if($('.quick-buy-btn').length) {
    //                     $('.quick-buy-btn').attr('data-size', sizeBtn);
    //                     $('.quick-buy-btn').attr('data-color', color);
    //                 }
    //             }
    //         },600)
    //     }
    //     if($('.check-availability-js').length) {
    //         $('.availability-js').html($('.buy-btn').text());
    //     }
    // }

    if($('.good_page').length) {
        if(!$('.choice-details').length) {
            $('button').removeClass("btn-disabled-js");
        }
    }

    $(document).on('click', '.sort_item-btn', function(e) {
        $(this).toggleClass("active");
        $(this).find(".sort_item-wrap").slideToggle();
    });

    $('.js_load_size').click(function(e) {
        var sortItemText = $(this).text()
        var sortList = $(this).closest('.sort_item-wrap');
        var sortBtnText = $(this).closest('.sort_item-btn').find('.sort_item-btn-text');
        var sortColorBtn = $(this).closest('.sort_item-btn');
        sortBtnText.text(sortItemText);
        sortColorBtn.addClass('selected');
        sortColorBtn.removeClass('red_border');
        sortList.slideToggle();
        $(this).closest('.sort_item-btn').toggleClass('active');
    })

    $(".catalogue__sort-mb").click(function (e) {
        $(this).toggleClass("active");
        $(".catalogue__sort-form").slideToggle();
    })

    if($('.btn-disabled-js').length) {
        $('.btn-disabled-js').on('click mouseover',function(e) {
            e.preventDefault();
            e.stopPropagation();
            $(".sort_item-btn").each(function() {
                if (!$(this).hasClass('selected')) {
                    $(this).addClass('red_border');
                }
            });
        })
        $('.btn-disabled-js').on('mouseout',function(e) {
            $(".sort_item-btn").each(function() {
                if (!$(this).hasClass('selected')) {
                    $(this).removeClass('red_border');
                }
            });
        })
    }

    if($('.size-js').length) {
        $('.size-js').click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (!$('.color-js').hasClass('selected')) {
                $('.color-js').addClass('red_border');
            }
        })
    }

    $('.tooltip').tooltipster({
          maxWidth: 350,
          trigger : 'custom' ,
            triggerOpen : {
                mouseenter : true ,
                tap : true },
            triggerClose : {
                mouseleave : true ,
                tap : true },
        //   functionPosition: function(instance, helper, position){
	    //     position.coord.left += 35;
	    //     return position;
	    // }
    });
    // position sticky
    if($('[data-sticky]').length) {
        Stickyfill.forceSticky()
        Stickyfill.add(document.querySelectorAll('[data-sticky]'));
    }
    // scroll to details good page
    scrollDetailsProduct();

    // share open
    $('.share-toggle-btn').click(function() {
        $(this).closest('.share-block').find('.share-socials-wrap').toggleClass('active');
    });
    // slider main page
    // if($('.visual-item').length) {
    //     $('.visual-item').on('click', function(e) {
    //         if($(this).hasClass('active')) {
    //             $(this).removeClass('active');
    //         } else {
    //             $('.visual-item').removeClass('active');
    //             $(this).addClass('active');
    //         }
    //     })
    // }
    if($('.js_visual_item').length) {
        $('.js_visual_item').on('click', function(e) {
            if($(this).hasClass('active')) {
                $(this).removeClass('active');
                $('.js_visual_open').removeClass('active');
            } else {
                $('.js_visual_item').removeClass('active');
                $('.js_visual_open').removeClass('active');
                $(this).addClass('active');
                $(this).next('.js_visual_open').addClass('active');
            }
        })
    }
    $('.card-description-inner').on('click', function(e) {
        e.stopPropagation();
    })
    // sort change
    if($('.search-info-sort').length) {
        $('.sort-form').change(function() {
            $('#sortTrigger').submit();
        })
    }

    // mobile sort open
    if($('.catalogue__filter-mob').length) {
        $('.catalogue__filter-mob').on('click', function() {
            $('.catalogue-list-filters').toggleClass("active");
            $('.filters-overlay').toggleClass("active");
            $('body').toggleClass("overlay");
            $('html').toggleClass('not-overflow');
        });
    }
    // close mobile sort
    $('.filters-overlay').click(function (e) {
         closeMobileFilter();
     });

    $('.filter-close').click(function (e) {
        closeMobileFilter();
    });

    // address toggle
    $(".address_toggler").change(function (e) {
        if ($(this).is(":checked")) {
            $(".address_variants").slideUp(400);
            $(".address_variants").find('.form-control-js').each(function() {
                $(this).prop('disabled', true);
            });
            $(".city-js").prop('disabled', true);
            $("#"+$(this).val()).slideDown(400);
            $("#"+$(this).val()).find('.form-control-js').each(function() {
                $(this).prop('disabled', false);
            });
            var sityBlock = $("#"+$(this).val());
            if(sityBlock.find('.city-js').length) {
                $(".city-js").prop('disabled', false);
            }
        }
    });


    // selectric init
    if($('.sort_wrap').length) {
        $('.sort_wrap').selectric({
            disableOnMobile: false,
            nativeOnMobile: false,
        })
    }

    // if($('.choice-size-js').length) {
    //     $('.choice-size-js').change(function(e) {
    //         var sizeValue = $('.choice-size-js :selected').attr('data-article');
    //         $('.article-js').html(sizeValue);
    //     })
    // }

    // tabs to accordeon
	initTabs_to_accordeon();

	function initTabs_to_accordeon() {
		if($('.tabs').length) {
			$('.tabs').each(function() {
				var active_tab = $(this).find('.tab-link').first();
				var tabs_container = $(this).closest('.tabs-container');
		        var selectTab = active_tab.attr('data-tab');
				active_tab.addClass('active');
				tabs_container.find('.tab-content').hide();
		        $('#'+selectTab).fadeIn();
		        $(".accordion-link[data-accord^='" + selectTab + "']").addClass("active");
			});

			$('.tabs li').click(function(e) {
		    	var parent = $(this).closest('.tabs-container');
		    	var selectTab = $(this).attr('data-tab');
		        parent.find('.tabs li').removeClass('active');
		        $(this).addClass('active');
		        parent.find('.tab-content').hide();
		        $('#'+selectTab).fadeIn();
                $(".accordion-link").removeClass("active");
                $(".accordion-link[data-accord^='" + selectTab + "']").addClass("active");
		    });

		    $(".accordion-link").on("click", function() {
                var d_activeTab = $(this).attr("data-accord");
                if($(this).hasClass('active')) {
                    $(".tab-content").slideUp(200);
                    $(".accordion-link").removeClass("active");
                    $(".tab-link").removeClass("active");
                } else {
                    $(".tab-content").slideUp(200);
                    $("#" + d_activeTab).slideDown(200);
                    $(".accordion-link").removeClass("active");
                    $(this).addClass("active");
                    $(".tab-link").removeClass("active");
                    $(".tab-link[data-tab^='" + d_activeTab + "']").addClass("active");
                }
			});
		}
	}

    // CustomScrollbar
    if($('.scroll-js').length) {
        initScrollbar();
    }

    // lightcase gallery
    if($('a[data-rel="lightcase:gallery"]').length) {
        initLightcase();
    }

  // open brand caption
  var btnOpen = $('.brand-more-btn'),
      content = btnOpen.closest('.brand-caption'),
      contentHeight = content.height();


  $('.brand-more-btn').on('click', function() {
    if(!$(this).hasClass('js_open')) {
      $(this).addClass('js_open');
      content.addClass('js_open');

    } else {
      $(this).removeClass('js_open');
      content.removeClass('js_open');
    }
  });

  // open contact-form
  $('.contacts__btn--show').on('click', function () {
      let btnSend = $(this).closest('.contacts__btns').find('.contacts__btn--send');
      let form = $(this).closest('.contacts__btns').next('.contacts__form');
      $(this).hide();
      $(btnSend).show();
      $(form).slideDown();
      placeholderInput();
  });

  // burger menu start
    $('.burger-menu').on('click', function() {
        $(document).one('keydown', function(e) {
            if (e.keyCode == 27) {
                $('.menu__icon').removeClass('open');
                $('.header-nav').removeClass('open');
                if (window.innerWidth > 1024) {
                    $('.right-block').removeClass('open-menu');
                }
                $('.main-cat-link').removeClass('active');
                $('body').removeClass('overlay');
                $('html').removeClass('not-overflow');
                if (window.innerWidth < 767) {
                    $('.subcategories-nav').css({'z-index' : '-1'});
                }
            }
        });

        if($('.header-nav').hasClass('open')) {
            $('.menu__icon').removeClass('open');
            $('.header-nav').removeClass('open');
            if (window.innerWidth > 1024) {
                $(this).closest('.header-main ').find('.right-block').removeClass('open-menu');
            }
            $('.main-cat-link').removeClass('active');
            $('body').removeClass('overlay');
            $('html').removeClass('not-overflow');
            if (window.innerWidth < 767) {
                $('.subcategories-nav').css({'z-index' : '-1'});
            }
        } else {
            $('.menu__icon').addClass('open');
            $('.header-nav').addClass('open');
            if (window.innerWidth > 1024) {
                $(this).closest('.header-main ').find('.right-block').addClass('open-menu');
            }
            $('body').addClass('overlay');
            $('html').addClass('not-overflow');
        }
    });
  // burger menu end

  // nav-menu start
  $('.has-submenu').on('click', function(e) {
      $('.subcategories-nav').removeClass('active');
      $('.main-cat-link').removeClass('active');
      $(this).addClass('active');
      var active_sub_menu = $(this).attr('data-id');
      if (window.innerWidth < 769) {
          $('#'+active_sub_menu).css({'z-index' : '1'});
      } else {
          $('#'+active_sub_menu).addClass('active');
      }
  })

  $('.subcategories-nav-close').on('click', function() {
      var close_btn = $(this).closest('.subcategories-nav').attr('id');
      if (window.innerWidth < 769) {
          $(this).closest('.subcategories-nav').css({'z-index' : '-1'});
          $('[data-id='+ close_btn +']').removeClass('active');
      } else {
          $(this).closest('.subcategories-nav').removeClass('active');
          $('[data-id='+ close_btn +']').removeClass('active');
      }
  })
  // nav-menu end

  $('.header-contact-name-wrap').click(function(e) {
        $(this).toggleClass('active');
        var elem = $(this).closest('.header-contact-name-wrap');
        if(!$(elem).hasClass("active")) {
           $('.header-contact-name-wrap').removeClass('active');
           $('.header-contact-info').removeClass('active');
           $(this).closest('.header-contact-name-wrap').removeClass('active');
           $(this).closest('.header-contact-item').find('.header-contact-info').removeClass('active');
        } else {
           $('.header-contact-name-wrap').removeClass('active');
           $('.header-contact-info').removeClass('active');
           $(this).closest('.header-contact-name-wrap').addClass('active');
           $(this).closest('.header-contact-item').find('.header-contact-info').addClass('active');

        }
   });
});

$(window).on('load scroll', function() {
    //   fixed header
    if ($(window).scrollTop() >= 40) {
        $('.header-main').addClass("bg-white");

    } else {
        $('.header-main').removeClass("bg-white");
    }
});

$(window).on('load resize', function () {
    if (window.innerWidth > 1024 ) {
        // for solution page
        if ($('.interactive-page').length && $('.dot').hasClass('dot-click-js')) {
            $('.dot').each(function() {
                $(this).removeClass('dot-click-js');
            });
        }
        // for mobile footer
        if($('.footer-item-name').hasClass('footer-name-js')) {
            $('.footer-item-name').removeClass('footer-name-js');
            $('.footer-item-name').removeClass('active');
            $('.footer-item-icon').removeClass('active');
            $('.footer-item-nav').css('display', 'block');
        }

        if($('.tabs').length && !$('.tab-link').hasClass('active')) {
            $('.tabs').each(function() {
				var active_tab = $(this).find('.tab-link').first();
				var tabs_container = $(this).closest('.tabs-container');
		        var selectTab = active_tab.attr('data-tab');
				active_tab.addClass('active');
				tabs_container.find('.tab-content').hide();
		        $('#'+selectTab).fadeIn();
		        $(".accordion-link[data-accord^='" + selectTab + "']").addClass("active");
			});
        }

        // destroy slider product card
        if ($('.product-img-block').hasClass('slick-slider')) {
            $(".product-img-block").slick('destroy');
        }
        //  remove sticky good page
         if($('.product-info-block-wrap').length) {
             Stickyfill.add($('.product-info-block-wrap'));
         }
         //  remove sticky filter-block
          if($('.filter-block').length) {
              Stickyfill.add($('.filter-block'));
          }
          // destroy custom scroll filter block
          if($('.filter-block').length) {
              if ($('.filter-block').hasClass('scroll-js')) {
                  initScrollbar();
              }
          }
    } else {
        // for solution page
        if ($('.interactive-page').length && !$('.dot').hasClass('dot-click-js')) {
            function addDotClick(){
                $('.dot').each(function() {
                    $(this).addClass('dot-click-js');
                })
            }
            setTimeout( addDotClick , 500);
        }
        //  remove sticky filter-block
        if($('.filter-block').length) {
            setTimeout(function(){
                Stickyfill.remove($('.filter-block'));
            },100)
        }
        // destroy custom scroll filter block
        if($('.filter-block').length) {
            if ($('.filter-block').hasClass('scroll-js')) {
                setTimeout(function(){
                    $('.filter-block.scroll-js').mCustomScrollbar("destroy");
                },100)
            }
        }
        // for mobile footer
        if(!$('.footer-item-name').hasClass('footer-name-js')) {
            $('.footer-item-name').addClass('footer-name-js');
            $('.footer-item-nav').css('display', 'none');
        }

        // init slider product card
        if (!$('.product-img-block').hasClass('slick-slider')) {
             $(".product-img-block").slick({
                 dots: true,
                 arrows: false,
                 infinite: false,
             });

         }
        //  remove sticky good page
         if($('.product-info-block-wrap').length) {
             Stickyfill.remove($('.product-info-block-wrap'));
         }
    }


    if (window.innerWidth > 768) {
        // tabs to accordeon good page
         accordeonDetailsProduct(false);
         if($('.product-descriptions__inner').length) {
             if ($('.product-descriptions__inner').hasClass('scroll-js')) {
                 initScrollbar();
             }
         }
         // parallax effect
         if($('.good_page').length) {
             setTimeout(function(){
                 var imgBlockHeight = $(".product-img-block-wrap").outerHeight();
                 $('.product-info-block').css('margin-top', '0');
             }, 100);
         }
    } else {
        // tabs to accordeon good page
        accordeonDetailsProduct();
        if($('.product-descriptions__inner').length) {
            if ($('.product-descriptions__inner').hasClass('scroll-js')) {
                $('.product-descriptions__inner.scroll-js').mCustomScrollbar("destroy");
            }
        }
        // parallax effect
        if($('.good_page').length) {
            setTimeout(function(){
                var imgBlockHeight = $(".product-img-block-wrap").outerHeight();
                $('.product-info-block').css('margin-top', imgBlockHeight);
            }, 100);
        }
    }


    if (window.innerWidth < 577 ) {
        $('.cabinet').prependTo( $( ".mobile-header-block" ) );
        $('.language').prependTo( $( ".mobile-header-block" ) );

    } else {
        $('.cabinet').insertAfter( $( ".header-search" ) );
        $('.language').insertBefore( $( ".header-search" ) );

    }
});
