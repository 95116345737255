$(document).ready( function(){
    // Inputmask
    $('input[type=tel]').each(function() {
        $(this).keypress(function(e, a) {
            if ((e.which == 13) || (e.keyCode == 13)) {
                $(this).blur();
            }
            return true;
        });
    });
    Inputmask({
        mask: '+38 (999) 999-99-99',
        clearMaskOnLostFocus: true,
        clearIncomplete: true,
        showMaskOnHover: false,
    }).mask('input[type=tel]');

    $("#login_form").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input-block'));
        },
        highlight: function(element) {
            $(element).parents('.input-wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input-wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#login_form").serialize();
            $.ajax({
                url: routes.postLogin,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                },
                success: function(data) {
                    switch (data) {
                        case "wrong_pass":
                            $("#login_form .auth-error").slideDown(200);
                            break;
                        case "success":
                            window.location.reload(true);
                            break
                    }
                }
            });
        }
    });

    $("#do_login").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        $("#login_form").submit();
    });

    $(".a-logout").click(function(e) {
      e.stopPropagation();
      e.preventDefault();
      var csrf_token = $('meta[name="csrf-token"]').attr('content');
      $.ajax({
          url: routes.postLogout,
          type: 'POST',
          data: {
              "_token" : csrf_token,
          },
          success: function(data) {
              switch (data) {
                  case "success":
                    var href = window.location.href;
                    var return_href = href.split('#')[0];

                      window.location.href = return_href;
                      break
              }
          }
      });
    });

    $("#reg_form").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
          errorPlacement: function(error, element) {
              error.appendTo($(element).parents('.input-block'));
          },
          highlight: function(element) {
              $(element).parents('.input-wrapper').addClass("forms__error");
          },
          unhighlight: function(element) {
              $(element).parents('.input-wrapper').removeClass("forms__error");
          },
          submitHandler: function(form) {
              var csrf_token = $('meta[name="csrf-token"]').attr('content');
              var formdata = $("#reg_form").serialize();
              $.ajax({
                  url: routes.postRegister,
                  type: 'POST',
                  data: {
                      "_token" : csrf_token,
                      "data": formdata,
                  },
                  success: function(data) {
                      switch (data) {
                        case "email":
                            $("#reg_form .auth-error").slideDown(200);
                            break;
                        case "deleted":
                            $("#reg_form .auth-del").slideDown(200);
                            break;
                        case "success":
                            window.location.href = window.location.origin + "/cabinet/";
                            break
                      }
                  }
              })
          }
    });
    $('#do_reg').click(function(e) {
        e.preventDefault();
        $("#reg_form").submit()
    });

    $("#reset_form").validate({
        errorElement: 'span',
        errorClass: 'span__error',
        ignoreTitle: true,
        errorPlacement: function(error, element) {
            error.appendTo($(element).parents('.input-block'));
        },
        highlight: function(element) {
            $(element).parents('.input-wrapper').addClass("forms__error");
        },
        unhighlight: function(element) {
            $(element).parents('.input-wrapper').removeClass("forms__error");
        },
        submitHandler: function(form) {
            var csrf_token = $('meta[name="csrf-token"]').attr('content');
            var formdata = $("#reset_form").serialize();
            $.ajax({
                url: routes.postPassword,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                },
                success: function(data) {
                    switch (data) {
                        case "no_email":
                            $("#reset_form .auth-error").slideDown(200);
                            $("#reset_form .auth-restored").slideUp(200);
                            break;
                        case "success":
                            $("#no_email").addClass("hide");
                            $("#reset_form .auth-restored").slideDown(200);
                            $("#reset_form .auth-error").slideUp(200);
                            setTimeout(function() {
                              $('.side-popup').removeClass('open');
                              $('body').removeClass('overlay');
                              $('html').removeClass('not-overflow');
                            }, 1000);
                            break
                    }
                }
            });
        }
    });
    $("#do_reset").click(function(e) {
        e.preventDefault();
        $("#reset_form").submit()
    });

    let feedback = $('.contacts__btn--send');

    $(feedback).each(function() {
        let feedbackId = $(this).attr('data-id');
        let feedbackBtn = $(`#feedback_send_${feedbackId}`);
        let feedbackShow = $(`#feedback_show_${feedbackId}`);
        let feedbackForm = $(`#feedback_form_${feedbackId}`);
        let feedbackSuccess = $(`#feedback_success_${feedbackId}`);


        $(feedbackForm).validate({
            errorElement: 'span',
            errorClass: 'span__error',
            ignoreTitle: true,
            errorPlacement: function(error, element) {
                error.appendTo($(element).parents('.input-block'));
            },
            highlight: function(element) {
                $(element).parents('.input-wrapper').addClass("forms__error");
            },
            unhighlight: function(element) {
                $(element).parents('.input-wrapper').removeClass("forms__error");
            },
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(feedbackForm).serialize();
                $(feedbackForm)[0].reset();
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                        "subj": "feedback"
                    },
                    success: function(data) {
                        $(".form-input").val("").blur();
                        $(feedbackSuccess).show();
                        setTimeout(function() {
                            $(feedbackBtn).hide();
                            $(feedbackForm).hide();
                            $(feedbackSuccess).hide();
                            $(feedbackShow).show()
                        }, 1000);
                    }
                })
            }
        });

        $(feedbackBtn).on("click", function(e) {
            e.stopPropagation();
            e.preventDefault();
            $(feedbackForm).submit();
        });

    });

    // validate form callback
    $(".do_callback_form").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.callback_form');
        formCallbackValidate(formVal);
        formVal.submit();
    });
    function formCallbackValidate(form) {
        form.validate({
            errorElement: 'span',
            errorClass: 'span__error',
            ignoreTitle: true,
            errorPlacement: function(error, element) {
                error.appendTo($(element).parents('.input-block'));
            },
            highlight: function(element) {
                $(element).parents('.input-wrapper').addClass("forms__error");
            },
            unhighlight: function(element) {
                $(element).parents('.input-wrapper').removeClass("forms__error");
            },
            submitHandler: function(form) {
              var csrf_token = $('meta[name="csrf-token"]').attr('content');
              var formdata = $(form).serialize();
              $(form)[0].reset();
              $.ajax({
                url: routes.postSend,
                type: 'POST',
                data: {
                    "_token" : csrf_token,
                    "data": formdata,
                    "subj": "callback"
                },
                success: function(data) {
                    $(".form-input").val("").blur();
                    $(form).closest('.popup-main').find('.popup-main-info').hide();
                    $(form).hide();
                    $(form).next('.form-thanks').show();
                    function hidePopup(){
                        $('.callback').removeClass('open');
                        $('body').removeClass('overlay');
                        $('html').removeClass('not-overflow');
                    }
                    function showForm(){
                        $(form).next('.form-thanks').hide();
                        $(form).closest('.popup-main').find('.popup-main-info').show();
                        $(form).show();
                    }
                    setTimeout( hidePopup ,1000);
                    setTimeout( showForm ,1000);
                }
              });
            }
        });
    }

    // quick form start
    function formValidateQuickOrder(form) {
        form.validate({
            errorElement: 'span',
            errorClass: 'span__error',
            ignoreTitle: true,
            errorPlacement: function(error, element) {
                error.appendTo($(element).parents('.input-block'));
            },
            highlight: function(element) {
                $(element).parents('.input-wrapper').addClass("forms__error");
            },
            unhighlight: function(element) {
                $(element).parents('.input-wrapper').removeClass("forms__error");
            },
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(form).serialize();
                $(form)[0].reset();
                $.ajax({
                    url: routes.postFastOrder,
                    type: 'POST',
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                    },
                    success: function(data) {
                        $(".form-input").val("").blur();
                        $(form).hide();
                        $(form).closest('.popup-main').find('.popup-main-info').hide();
                        $(form).next('.form-thanks').show();
                        function hidePopup(){
                            $(form).closest('.quick-order').removeClass('open');
                            $('body').removeClass('overlay');
                            $('html').removeClass('not-overflow');
                        }
                        function showForm(){
                            $(form).next('.form-thanks').hide();
                            $(form).show();
                            $(form).closest('.popup-main').find('.popup-main-info').show();
                        }
                        setTimeout( hidePopup ,1000);
                        setTimeout( showForm ,1000);
                    }
                })
            }
        });
    };
    $(".do_quick-order").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.quick-order_form');
        formValidateQuickOrder(formVal);
        formVal.submit();
    });
    // quick form end

    // get_good start
    function formValidateGetGood(form) {
        form.validate({
            errorElement: 'span',
            errorClass: 'span__error',
            ignoreTitle: true,
            errorPlacement: function(error, element) {
                error.appendTo($(element).parents('.input-block'));
            },
            highlight: function(element) {
                $(element).parents('.input-wrapper').addClass("forms__error");
            },
            unhighlight: function(element) {
                $(element).parents('.input-wrapper').removeClass("forms__error");
            },
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(form).serialize();
                $(form)[0].reset();
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                        "subj": "request_good"
                    },
                    success: function(data) {
                        $(".form-input").val("").blur();
                        $(form).hide();
                        $(form).next('.form-thanks').show();
                        function hidePopup(){
                            $('.get_good_popup').removeClass('open');
                            $('body').removeClass('overlay');
                            $('html').removeClass('not-overflow');
                        }
                        function showForm(){
                            $(form).next('.form-thanks').hide();
                            $(form).show();
                        }
                        setTimeout( hidePopup ,1000);
                        setTimeout( showForm ,1000);
                    }
                })
            }
        });
    };
    $(".do_get_good").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.get_good_form');
        formValidateGetGood(formVal);
        formVal.submit();
    });
    // get_good end

    // get_price start
    function formValidateGetPrice(form) {
        form.validate({
            errorElement: 'span',
            errorClass: 'span__error',
            ignoreTitle: true,
            errorPlacement: function(error, element) {
                error.appendTo($(element).parents('.input-block'));
            },
            highlight: function(element) {
                $(element).parents('.input-wrapper').addClass("forms__error");
            },
            unhighlight: function(element) {
                $(element).parents('.input-wrapper').removeClass("forms__error");
            },
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(form).serialize();
                $(form)[0].reset();
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                        "subj": "request_price"
                    },
                    success: function(data) {
                        $(".form-input").val("").blur();
                        $(form).hide();
                        $(form).next('.form-thanks').show();
                        function hidePopup(){
                            $('.get_price_popup').removeClass('open');
                            $('body').removeClass('overlay');
                            $('html').removeClass('not-overflow');
                        }
                        function showForm(){
                            $(form).next('.form-thanks').hide();
                            $(form).show();
                        }
                        setTimeout( hidePopup ,1000);
                        setTimeout( showForm ,1000);
                    }
                })
            }
        });
    };
    $(".do_get_price").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.get_price_form');
        formValidateGetPrice(formVal);
        formVal.submit();
    });
    // get_price end


    // get_good start
    function formValidateRequestManager(form) {
        form.validate({
            errorElement: 'span',
            errorClass: 'span__error',
            ignoreTitle: true,
            errorPlacement: function(error, element) {
                error.appendTo($(element).parents('.input-block'));
            },
            highlight: function(element) {
                $(element).parents('.input-wrapper').addClass("forms__error");
            },
            unhighlight: function(element) {
                $(element).parents('.input-wrapper').removeClass("forms__error");
            },
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(form).serialize();
                $(form)[0].reset();
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                        "subj": "request_manager"
                    },
                    success: function(data) {
                        $(".form-input").val("").blur();
                        $(form).hide();
                        $(form).next('.form-thanks').show();
                        function hidePopup(){
                            $('.request_manager_popup').removeClass('open');
                            $('body').removeClass('overlay');
                            $('html').removeClass('not-overflow');
                        }
                        function showForm(){
                            $(form).next('.form-thanks').hide();
                            $(form).show();
                        }
                        setTimeout( hidePopup ,1000);
                        setTimeout( showForm ,1000);
                    }
                })
            }
        });
    };
    $(".do_request_manager").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.request_manager_form');
        formValidateRequestManager(formVal);
        formVal.submit();
    });
    // get_good end


    // оформление заказа
    function formValidateDoOrder(form) {
        form.validate({
            errorElement: 'span',
            errorClass: 'span__error',
            ignoreTitle: true,
            errorPlacement: function(error, element) {
                error.appendTo($(element).parents('.input-block'));
            },
            highlight: function(element) {
                $(element).parents('.input-wrapper').addClass("forms__error");
            },
            unhighlight: function(element) {
                $(element).parents('.input-wrapper').removeClass("forms__error");
            },
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(form).serialize();
                $(form)[0].reset();
                $.ajax({
                    url: routes.postDoOrder,
                    type: 'POST',
                    beforeSend: function(){
                        $('#spin').show();
                    },
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                    },
                    success: function(data) {
                        $(".form-input").val("").blur();
                        $('#spin').hide();
                        $(form).hide();
                        $(form).next('.form-thanks').show();
                        $('html, body').animate({ scrollTop: 0 });
                        function toHome(){
                            window.location.href = "/";
                        }
                        setTimeout( toHome ,1000);
                    }
                })
            }
        });
    };

    $("#do_order_btn").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        if ($('#agree').prop('checked')){
            var formVal = $(this).closest('.order_form');
            formValidateDoOrder(formVal);
            formVal.submit();
        } else{
            $('.text-agreement').css('display','none');
            $('.text-disagree').css('display','block');
        }


    });

    $('#agree').on('change', function(){
        if ($('#agree').prop('checked')){
            $('.text-agreement').css('display','block');
            $('.text-disagree').css('display','none');
            $('#do_order_btn').removeClass('disabled');
        } else{
            $('.text-agreement').css('display','none');
            $('.text-disagree').css('display','block');
            $('#do_order_btn').addClass('disabled');
        }
    });
    // оформление заказа

    // validate form callback
    $(".do_registration_tour").click(function(e) {
        e.stopPropagation();
        e.preventDefault();
        var formVal = $(this).closest('.registration_tour_form');
        formRegistrationTourValidate(formVal);
        formVal.submit();
    });
    function formRegistrationTourValidate(form) {
        form.validate({
            errorElement: 'span',
            errorClass: 'span__error',
            ignoreTitle: true,
            errorPlacement: function(error, element) {
                error.appendTo($(element).parents('.input-block'));
            },
            highlight: function(element) {
                $(element).parents('.input-wrapper').addClass("forms__error");
            },
            unhighlight: function(element) {
                $(element).parents('.input-wrapper').removeClass("forms__error");
            },
            submitHandler: function(form) {
                var csrf_token = $('meta[name="csrf-token"]').attr('content');
                var formdata = $(form).serialize();
                $(form)[0].reset();
                $.ajax({
                    url: routes.postSend,
                    type: 'POST',
                    data: {
                        "_token" : csrf_token,
                        "data": formdata,
                        "subj": "tour_registration"
                    },
                    success: function(data) {
                        $(".form-input").val("").blur();
                        $(form).closest('.popup-main').find('.popup-main-info').hide();
                        $(form).hide();
                        $(form).next('.form-thanks').show();
                        function hidePopup(){
                            $('.registration_tour_popup').removeClass('open');
                            $('body').removeClass('overlay');
                            $('html').removeClass('not-overflow');
                        }
                        function showForm(){
                            $(form).next('.form-thanks').hide();
                            $(form).closest('.popup-main').find('.popup-main-info').show();
                            $(form).show();
                        }
                        setTimeout( hidePopup ,1000);
                        setTimeout( showForm ,1000);
                    }
                });
            }
        });
    }

});
